import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";
import { activateSweetAlert, deActivateSweetAlert } from "../../SweetAlert";

interface AddChecklistState {
    addChecklist: {
        load: boolean;
        data: [];
    };
}

export const addChecklistSlice = createSlice({
    name: "addChecklist",
    initialState: {
        addChecklist: {
            load: false,
            data: [],
        },
    } as AddChecklistState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(getChecklist.pending, (state: AddChecklistState) => {
                state.addChecklist.load = true;
            })
            .addCase(getChecklist.fulfilled, (state: AddChecklistState, action: any) => {
                state.addChecklist.data = action.payload;
                state.addChecklist.load = false;
            })
            .addCase(getChecklist.rejected, (state: AddChecklistState) => {
                state.addChecklist.load = false;
            })
            .addCase(getEditChecklist.pending, (state: AddChecklistState) => {
                state.addChecklist.load = true;
            })
            .addCase(getEditChecklist.fulfilled, (state: AddChecklistState, action: any) => {
                state.addChecklist.data = action.payload;
                state.addChecklist.load = false;
            })
            .addCase(getEditChecklist.rejected, (state: AddChecklistState) => {
                state.addChecklist.load = false;
            });
    },
});

export default addChecklistSlice.reducer;

export const getChecklist = createAsyncThunk(
    "addChecklist/getChecklist",
    async (param: { apiParam: { element_pin: any, warehouse_id: any, tpi_firm_id: any, page: any, per_page: any, search: any }, navigation: any }, thunkAPI) => {
        try {
            thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/getElementCheckList", {
                warehouse_id: param.apiParam.warehouse_id,
                element_pin: param.apiParam.element_pin,
                tpi_firm_id: param.apiParam.tpi_firm_id,
                page: param.apiParam.page,
                per_page: param.apiParam.per_page,
                search: param.apiParam.search
            });

            thunkAPI.dispatch(toggleLoading());
            return data.data;
        } catch (error) {
            thunkAPI.dispatch(toggleLoading());
            // console.log("Error", error);
        }
    }
);

export const getEditChecklist = createAsyncThunk(
    "addChecklist/getEditChecklist",
    async (param: { apiParam: { element_pin: any, warehouse_id: any, tpi_firm_id: any }, navigation: any }, thunkAPI) => {
        try {
            thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/postEditElementChecklist", {
                warehouse_id: param.apiParam.warehouse_id,
                element_pin: param.apiParam.element_pin,
                tpi_firm_id: param.apiParam.tpi_firm_id,
            });

            thunkAPI.dispatch(toggleLoading());
            return data.data;
        } catch (error) {
            thunkAPI.dispatch(toggleLoading());
            // console.log("Error", error);
        }
    }
);

export const addNewChecklist = createAsyncThunk(
    "addChecklist/addNewChecklist",
    async (param: { apiParam: { element_pin: any, element_checklist: any, warehouse_id: any, tpi_firm_id: any, element_name: any }, navigation: any }, thunkAPI) => {
        try {
            thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/postElementChecklist", {
                warehouse_id: param.apiParam.warehouse_id,
                element_pin: param.apiParam.element_pin,
                element_checklist: JSON.stringify(param.apiParam.element_checklist),
                status: "1",
                tpi_firm_id: param.apiParam.tpi_firm_id
            });

            thunkAPI.dispatch(
                activateSweetAlert({
                    show: true,
                    type: "success",
                    title: "Success!",
                    confirmBtnText: 'Done',
                    message: data.message,
                    onConfirm: () => {
                        handleonConfirm()
                    },
                })
            );

            const handleonConfirm = () => {
                param.navigation("/element-checklist-summary", { state: { element_name: param.apiParam.element_name, element_pin: param.apiParam.element_pin } });
                thunkAPI.dispatch(deActivateSweetAlert());
            };

            thunkAPI.dispatch(toggleLoading());
            return data.data;
        } catch (error) {
            thunkAPI.dispatch(toggleLoading());
            // console.log("Error", error);
        }
    }
);

export const updateChecklist = createAsyncThunk(
    "addChecklist/updateChecklist",
    async (param: { apiParam: { element_pin: any, element_checklist: any, warehouse_id: any, tpi_firm_id: any, element_name: any }, navigation: any }, thunkAPI) => {
        try {
            thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/updateElementChecklist", {
                warehouse_id: param.apiParam.warehouse_id,
                element_pin: param.apiParam.element_pin,
                element_checklist: JSON.stringify(param.apiParam.element_checklist),
                tpi_firm_id: param.apiParam.tpi_firm_id
            });

            thunkAPI.dispatch(
                activateSweetAlert({
                    show: true,
                    type: "success",
                    title: "Success!",
                    confirmBtnText: 'Done',
                    message: data.message,
                    onConfirm: () => {
                        handleonConfirm()
                    },
                })
            );

            const handleonConfirm = () => {
                param.navigation("/element-checklist-summary", { state: { element_name: param.apiParam.element_name, element_pin: param.apiParam.element_pin } });
                thunkAPI.dispatch(deActivateSweetAlert());
            };

            thunkAPI.dispatch(toggleLoading());
            return data.data;
        } catch (error) {
            thunkAPI.dispatch(toggleLoading());
            // console.log("Error", error);
        }
    }
);

export const deleteChecklist = createAsyncThunk(
    "addChecklist/deleteChecklist",
    async (param: { apiParam: { element_checklist_pin: any, warehouse_id: any, tpi_firm_id: any }, navigation: any }, thunkAPI) => {
        try {
            thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/deleteElementChecklist", {
                warehouse_id: param.apiParam.warehouse_id,
                element_checklist_pin: param.apiParam.element_checklist_pin,
                tpi_firm_id: param.apiParam.tpi_firm_id
            });

            thunkAPI.dispatch(toggleLoading());
            return data.data;
        } catch (error) {
            thunkAPI.dispatch(toggleLoading());
            // console.log("Error", error);
        }
    }
);
