import axios from "axios";
import { API_URL } from "./config";
import decryptData from "./reusable/decryptData";

const loginUserData = decryptData();

const authHeader = () => ({
  Authorization: `Bearer ${loginUserData?.token}`,
});

const source = axios.CancelToken.source();

const API_ENDPOINT = API_URL;
const client = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    'Cache-Control': 'no-cache',
    "Content-Type": "multipart/form-data",
    "Access-Control-allow-Origin": "*",
    "Accept": "*/*",
  },
});

class DataServer {
  static get(path = "", optionalHeader = {}, token = source.token) {
    return client({
      method: "GET",
      url: path,
      headers: { ...authHeader(), ...optionalHeader },
      cancelToken: token,
    });
  }

  static post(path = "", data = {}, optionalHeader = {}) {
    return client({
      method: "POST",
      url: path,
      data,
      headers: { ...authHeader(), ...optionalHeader }
    });
  }

  static patch(path = "", data = {}, optionalHeader = {}) {
    return client({
      method: "PATCH",
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader(), ...optionalHeader }
    });
  }

  static put(path = "", data = {}, optionalHeader = {}) {
    return client({
      method: "PUT",
      url: path,
      data: data,
      headers: { ...authHeader(), ...optionalHeader }
    });
  }

  static delete(path = "", data = {}, optionalHeader = {}) {
    return client({
      method: "DELETE",
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader(), ...optionalHeader }
    });
  }
}

client.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { response, config } = error;
    if (response) {
      switch (response.status) {
        case 401:
          throw response?.data;
          break;
        case 500:
          throw `Internal server error: ${response?.data?.errors || ""}`;
        case 422:
          throw response?.data;
        default:
          throw response?.data;
      }
    }
    return Promise.reject(error);
  }
);

export { DataServer };
