import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";

interface ComparedReportedIssuesState {
  comparedissue: {
    load: boolean;
    data: [];
  };
}

export const ComparedReportedIssueSlice = createSlice({
  name: "comparedissue",
  initialState: {
    comparedissue: {
      load: false,
      data: [],
    },
  } as ComparedReportedIssuesState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchComparedReportedIssuesList.pending, (state: ComparedReportedIssuesState) => {
        state.comparedissue.load = true;
      })
      .addCase(fetchComparedReportedIssuesList.fulfilled, (state: ComparedReportedIssuesState, action: any) => {
        state.comparedissue.data = action.payload;
        state.comparedissue.load = false;
      })
      .addCase(fetchComparedReportedIssuesList.rejected, (state: ComparedReportedIssuesState) => {
        state.comparedissue.load = false;
      });
  },
});

export default ComparedReportedIssueSlice.reducer;

export const fetchComparedReportedIssuesList = createAsyncThunk(
  "comparedissue/fetchComparedReportedIssuesList",
  async (param: {apiParam: {per_page: any, page: any, status: any, search: any, warehouse_id: any}}, thunkAPI) => {
    try {
      // thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post(`api/getTpiIssuesWithDuplicatesPartialData`, {
        warehouse_id: param.apiParam.warehouse_id,
        status: param.apiParam.status,
        per_page: param.apiParam.per_page,
        page: param.apiParam.page,
        search: param.apiParam.search
      });
      // thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      // thunkAPI.dispatch(toggleLoading());
    }
  }
);

