import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./store";
import NoInternetConnection from "./views/pages/NoInternetConnection";
import "./assets/css/views/responsive.scss";
import * as Sentry from "@sentry/react";
import InactivateUser from "./reusable/InactivateUser";
import decryptData from "./reusable/decryptData";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const loginuserid = decryptData();
const userid = loginuserid?.data?.id;
const sentry_token = loginuserid?.sentry_token;

// if (process.env.REACT_APP_ENV === "production" || process.env.REACT_APP_ENV === "staging") {
  // Sentry.init({
  //   dsn: sentry_token,
  //   environment: process.env.REACT_APP_ENV,
  //   integrations: [
  //     Sentry.browserTracingIntegration(),
  //     Sentry.replayIntegration({
  //       maskAllText: false,
  //       blockAllMedia: false,
  //     }),
  //   ],
  //   // Performance Monitoring
  //   tracesSampleRate: 1.0, //  Capture 100% of the transactions
  //   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  //   // tracePropagationTargets: ["https://demo.rams.digital/", "https://app.rams.digital"],
  //   // Session Replay
  //   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  //   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  // });

  // // Set the user context
  // Sentry.setUser({
  //   id: userid, // Set the user ID
  //   // You can also set other user information if available
  //   // email: "user@example.com",
  //   // username: "user123",
  // });
// }

root.render(
  <React.Fragment>
    <Provider store={store}>
      <BrowserRouter>
        <NoInternetConnection>
          {/* <InactivateUser></InactivateUser> */}
          <App />
        </NoInternetConnection>
      </BrowserRouter>
    </Provider>
  </React.Fragment>
);

reportWebVitals();
