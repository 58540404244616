import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface RackCountState {
  RackCount: {
    load: boolean;
    data: [];
  };
}

export const RackCountSlice = createSlice({
  name: "RackCount",
  initialState: {
    RackCount: {
      load: false,
      data: [],
    },
  } as RackCountState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchRackCount.pending, (state: RackCountState) => {
        state.RackCount.load = true;
      })
      .addCase(fetchRackCount.fulfilled, (state: RackCountState, action: any) => {
        state.RackCount.data = action.payload;
        state.RackCount.load = false;
      })
      .addCase(fetchRackCount.rejected, (state: RackCountState) => {
        state.RackCount.load = false;
      })
      .addCase(fetchInspectionRackCount.pending, (state: RackCountState) => {
        state.RackCount.load = true;
      })
      .addCase(fetchInspectionRackCount.fulfilled, (state: RackCountState, action: any) => {
        state.RackCount.data = action.payload;
        state.RackCount.load = false;
      })
      .addCase(fetchInspectionRackCount.rejected, (state: RackCountState) => {
        state.RackCount.load = false;
      });
  },
});

export default RackCountSlice.reducer;

export const fetchRackCount = createAsyncThunk(
  "RackCount/fetchRackCount",
  async (param: any) => {
    try {
      const { data } = await DataServer.post("api/frameCount", {
        warehouse_id: param.apiParam.warehouse_id,
        layout_id: param.apiParam.layout_id
      });
      return data.data;
    } catch (error) {
      console.log("Error in Login", error);
    }
  }
);

export const fetchInspectionRackCount = createAsyncThunk(
  "RackCount/fetchInspectionRackCount",
  async (param: {apiParam: { project_pin: any, layout_id: any }}) => {
    try {
      const { data } = await DataServer.post("api/rackInspectionCount", {
        project_pin: param.apiParam.project_pin,
        layout_id: param.apiParam.layout_id
      });
      return data.data;
    } catch (error) {
      return error;
    }
  }
);
