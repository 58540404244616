import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";

interface SubIssueListState {
  subIssueList: {
    load: boolean;
    data: [];
  };
}

export const subIssueListSlice = createSlice({
  name: "subIssueList",
  initialState: {
    subIssueList: {
      load: false,
      data: [],
    },
  } as SubIssueListState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchsubIssueList.pending, (state: SubIssueListState) => {
        state.subIssueList.load = true;
      })
      .addCase(fetchsubIssueList.fulfilled, (state: SubIssueListState, action: any) => {
        state.subIssueList.data = action.payload;
        state.subIssueList.load = false;
      })
      .addCase(fetchsubIssueList.rejected, (state: SubIssueListState) => {
        state.subIssueList.load = false;
      })
      .addCase(fetchMastersubIssueList.pending, (state: SubIssueListState) => {
        state.subIssueList.load = true;
      })
      .addCase(fetchMastersubIssueList.fulfilled, (state: SubIssueListState, action: any) => {
        state.subIssueList.data = action.payload;
        state.subIssueList.load = false;
      })
      .addCase(fetchMastersubIssueList.rejected, (state: SubIssueListState) => {
        state.subIssueList.load = false;
      });
  },
});

export default subIssueListSlice.reducer;

export const fetchsubIssueList = createAsyncThunk(
  "subIssueList/fetchsubIssueList",
  async (param: { apiParam : {element_pin: any, element_issue_pin: any, warehouse_id: any, tpi_firm_id: number, page: any, per_page: any, search: any} }, thunkAPI) => {
    try {
      const { data } = await DataServer.post("api/getSubIssueList", {
        warehouse_id: param.apiParam.warehouse_id,
        element_pin: param.apiParam.element_pin,
        element_issue_pin: param.apiParam.element_issue_pin,
        tpi_firm_id: param.apiParam.tpi_firm_id,
        page: param.apiParam.page,
        per_page: param.apiParam.per_page,
        search: param.apiParam.search, 
      });
      return data.data;
    } catch (error) {
      thunkAPI.dispatch(toggleLoading());
    }
  }
);

export const fetchMastersubIssueList = createAsyncThunk(
  "subIssueList/fetchMastersubIssueList",
  async (param: { apiParam : {element_pin: any, element_issue_pin: any, page: any, per_page: any, search: any} }, thunkAPI) => {
    try {
      const { data } = await DataServer.post("api/getSubIssueMaster", {
        element_pin: param.apiParam.element_pin,
        element_issue_pin: param.apiParam.element_issue_pin,
        page: param.apiParam.page,
        per_page: param.apiParam.per_page,
        search: param.apiParam.search
      });
      return data.data;
    } catch (error) {
      thunkAPI.dispatch(toggleLoading());
    }
  }
);
