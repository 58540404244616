import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";
import { activateSweetAlert, deActivateSweetAlert } from "../../SweetAlert";

interface HealthParameterListState {
    HealthParameterList: {
        load: boolean;
        data: [];
    };
}

export const HealthParameterListSlice = createSlice({
    name: "HealthParameterList",
    initialState: {
        HealthParameterList: {
            load: false,
            data: [],
        },
    } as HealthParameterListState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchHealthParameterList.pending, (state: HealthParameterListState) => {
                state.HealthParameterList.load = true;
            })
            .addCase(fetchHealthParameterList.fulfilled, (state: HealthParameterListState, action: any) => {
                state.HealthParameterList.data = action.payload;
                state.HealthParameterList.load = false;
            })
            .addCase(fetchHealthParameterList.rejected, (state: HealthParameterListState) => {
                state.HealthParameterList.load = false;
            })
            .addCase(fetchDefaultWithcustomData.pending, (state: HealthParameterListState) => {
                state.HealthParameterList.load = true;
            })
            .addCase(fetchDefaultWithcustomData.fulfilled, (state: HealthParameterListState, action: any) => {
                state.HealthParameterList.data = action.payload;
                state.HealthParameterList.load = false;
            })
            .addCase(fetchDefaultWithcustomData.rejected, (state: HealthParameterListState) => {
                state.HealthParameterList.load = false;
            });
    },
});

export default HealthParameterListSlice.reducer;

export const fetchHealthParameterList = createAsyncThunk(
    "HealthParameterList/fetchHealthParameterList",
    async (param: { apiParam: { page: any, per_page: any, search: any, code: any, element_pin: any, warehouse_id: any, tpi_firm_id: any } }, thunkAPI) => {
        try {
            // thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/getElementHealthsParametersData", {
                warehouse_id: param.apiParam.warehouse_id,
                code: param.apiParam.code,
                element_pin: param.apiParam.element_pin, 
                page: param.apiParam.page,
                per_page: param.apiParam.per_page,
                search: param.apiParam.search,
                tpi_firm_id: param.apiParam.tpi_firm_id
            });
            // thunkAPI.dispatch(toggleLoading());
            return data.data;
        } catch (error) {
            // thunkAPI.dispatch(toggleLoading());
            // return(error);
        }
    }
);

export const fetchDefaultWithcustomData = createAsyncThunk(
    "HealthParameterList/fetchDefaultWithcustomData",
    async (param: { apiParam: { page: any, per_page: any, search: any, code: any, element_pin: any, warehouse_id: any, tpi_firm_id: any } }, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/getHealthParameterDefaultWithcustomData", {
                warehouse_id: param.apiParam.warehouse_id,
                code: param.apiParam.code,
                element_pin: param.apiParam.element_pin,
                page: param.apiParam.page,
                per_page: param.apiParam.per_page,
                search: param.apiParam.search,
                tpi_firm_id: param.apiParam.tpi_firm_id
            });

            thunkAPI.dispatch(
                activateSweetAlert({
                    show: true,
                    type: "success",
                    title: "Success!",
                    confirmBtnText: 'Done',
                    message: data.message,
                    onConfirm: () => {
                        handleonConfirm()
                    },
                })
            );

            const handleonConfirm = () => {
                thunkAPI.dispatch(fetchHealthParameterList({ apiParam: { code: param.apiParam.code, page: param.apiParam.page, per_page: param.apiParam.per_page, search: param.apiParam.search, element_pin: param.apiParam.element_pin, warehouse_id: param.apiParam.warehouse_id, tpi_firm_id: param.apiParam.tpi_firm_id } }));
                // param.navigation("/element-checklist-summary", { state: { element_name: param.apiParam.element_name, element_pin: param.apiParam.element_pin } });
                thunkAPI.dispatch(deActivateSweetAlert());
            };

            return data.data;

        } catch (error) {
            // return (error);
        }
    }
);
