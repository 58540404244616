import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";

interface EffectOfEventState {
  EffectOfEvent: {
    load: boolean;
    data: [];
  };
}

export const EffectOfEventSlice = createSlice({
  name: "EffectOfEvent",
  initialState: {
    EffectOfEvent: {
      load: false,
      data: [],
    },
  } as EffectOfEventState,
  reducers: {
    resetEffectOfEventState: (state: any) => {
        state.EffectOfEvent.data = []; // Reset state to initial values
    },
},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchEffectOfEvent.pending, (state: EffectOfEventState) => {
        state.EffectOfEvent.load = true;
      })
      .addCase(fetchEffectOfEvent.fulfilled, (state: EffectOfEventState, action: any) => {
        state.EffectOfEvent.data = action.payload;
        state.EffectOfEvent.load = false;
      })
      .addCase(fetchEffectOfEvent.rejected, (state: EffectOfEventState) => {
        state.EffectOfEvent.load = false;
      })
  },
});

export const { resetEffectOfEventState } = EffectOfEventSlice.actions;
export default EffectOfEventSlice.reducer;

export const fetchEffectOfEvent = createAsyncThunk(
  "EffectOfEvent/fetchEffectOfEvent",
  async (param: { apiParam : {code: any, probability_of_event: any, warehouse_id: any, tpi_firm_id: any} }, thunkAPI) => {
    try {      
      const { data } = await DataServer.post("api/getEffectOfEventListData", {
        warehouse_id: param.apiParam.warehouse_id,
        code: param.apiParam.code,
        probability_of_event: param.apiParam.probability_of_event,
        tpi_firm_id: param.apiParam.tpi_firm_id
      });

      return data.data;

    } catch (error) {
      return(error);
    }
  }
);
