import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";

interface IssueDiagnoseParameterState {
    IssueDiagnoseParameter: {
        load: boolean;
        data: [];
    };
}

export const IssueDiagnoseParameterSlice = createSlice({
    name: "IssueDiagnoseParameter",
    initialState: {
        IssueDiagnoseParameter: {
            load: false,
            data: [],
        },
    } as IssueDiagnoseParameterState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchIssueDiagnoseParameter.pending, (state: IssueDiagnoseParameterState) => {
                state.IssueDiagnoseParameter.load = true;
            })
            .addCase(fetchIssueDiagnoseParameter.fulfilled, (state: IssueDiagnoseParameterState, action: any) => {
                state.IssueDiagnoseParameter.data = action.payload;
                state.IssueDiagnoseParameter.load = false;
            })
            .addCase(fetchIssueDiagnoseParameter.rejected, (state: IssueDiagnoseParameterState) => {
                state.IssueDiagnoseParameter.load = false;
            })
    },
});

export default IssueDiagnoseParameterSlice.reducer;

export const fetchIssueDiagnoseParameter = createAsyncThunk(
    "IssueDiagnoseParameter/fetchIssueDiagnoseParameter",
    async (param: { apiParam: { element_name: any, element_issue_name: any, sub_issue_name: any, warehouse_id: any, tpi_firm_id: any } }, thunkAPI) => {
        try {
            const { data } = await DataServer.post("api/getIssueDiagnoseParameterData", {
                element_name: param.apiParam.element_name,
                element_issue_name: param.apiParam.element_issue_name,
                sub_issue_name: param.apiParam.sub_issue_name,
                tpi_firm_id: param.apiParam.tpi_firm_id,
                warehouse_id: param.apiParam.warehouse_id
            });
            return data.data;

        } catch (error) {
            return (error);
        }
    }
);
