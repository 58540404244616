import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";

interface IssuesState {
  issue: {
    load: boolean;
    data: [];
  };
}

export const IssueSlice = createSlice({
  name: "issuedetails",
  initialState: {
    issue: {
      load: false,
      data: [],
    },
  } as IssuesState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
 
      .addCase(fetchVerifiedIssuesDetails.pending, (state: IssuesState) => {
        state.issue.load = true;
      })
      .addCase(fetchVerifiedIssuesDetails.fulfilled, (state: IssuesState, action: any) => {
        state.issue.data = action.payload;
        state.issue.load = false;
      })
      .addCase(fetchVerifiedIssuesDetails.rejected, (state: IssuesState) => {
        state.issue.load = false;
      })
      .addCase(fetchReportedIssuesDetails.pending, (state: IssuesState) => {
        state.issue.load = true;
      })
      .addCase(fetchReportedIssuesDetails.fulfilled, (state: IssuesState, action: any) => {
        state.issue.data = action.payload;
        state.issue.load = false;
      })
      .addCase(fetchReportedIssuesDetails.rejected, (state: IssuesState) => {
        state.issue.load = false;
      })
      .addCase(fetchTpiReportedIssuesDetails.pending, (state: IssuesState) => {
        state.issue.load = true;
      })
      .addCase(fetchTpiReportedIssuesDetails.fulfilled, (state: IssuesState, action: any) => {
        state.issue.data = action.payload;
        state.issue.load = false;
      })
      .addCase(fetchTpiReportedIssuesDetails.rejected, (state: IssuesState) => {
        state.issue.load = false;
      });
  },
});

export default IssueSlice.reducer;



export const fetchVerifiedIssuesDetails = createAsyncThunk(
  "issuedetails/fetchVerifiedIssuesDetails",
  async (param: any, thunkAPI) => {
    try {
      thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post(`api/viewVerifiedIssues`, {
        verified_issue_detail_pin: param.apiParam.id,
        warehouse_id: param.apiParam.warehouse_id
      });
      thunkAPI.dispatch(toggleLoading());
      return data;
    } catch (error) {
      thunkAPI.dispatch(toggleLoading());
    }
  }
);


export const fetchReportedIssuesDetails = createAsyncThunk(
  "issuedetails/fetchReportedIssuesDetails",
  async (param: { apiParam: { id: any, warehouse_id: any } }, thunkAPI) => {
    try {
      thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post('api/viewReportedIssueDetail', {
        reported_issue_pin: param.apiParam.id,
        warehouse_id: param.apiParam.warehouse_id,
        // reported_by: param.apiParam.reported_by
      });
      thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      thunkAPI.dispatch(toggleLoading());
    }
  }
);

export const fetchTpiReportedIssuesDetails = createAsyncThunk(
  "issuedetails/fetchTpiReportedIssuesDetails",
  async (param: { apiParam: { id: any, warehouse_id: any } }, thunkAPI) => {
    try {
      thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post('api/tpiReportedIssueDetails', {
        tpi_reported_issue_pin: param.apiParam.id,
        warehouse_id: param.apiParam.warehouse_id
      });
      thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      thunkAPI.dispatch(toggleLoading());
    }
  }
);
