import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";

interface InspectorListState {
    inspectorlist: {
    load: boolean;
    data: [];
  };
}

export const InspectorListSlice = createSlice({
  name: "inspectorlist",
  initialState: {
    inspectorlist: {
      load: false,
      data: [],
    },
  } as InspectorListState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchInspectorList.pending, (state: InspectorListState) => {
        state.inspectorlist.load = true;
      })
      .addCase(fetchInspectorList.fulfilled, (state: InspectorListState, action: any) => {
        state.inspectorlist.data = action.payload;
        state.inspectorlist.load = false;
      })
      .addCase(fetchInspectorList.rejected, (state: InspectorListState) => {
        state.inspectorlist.load = false;
      })
  },
});

export default InspectorListSlice.reducer;

export const fetchInspectorList = createAsyncThunk(
  "inspectorlist/fetchInspectorList",
  async (param: {apiParam: {warehouse_id: any, issue_pin: any}}, thunkAPI) => {
    try {
      const { data } = await DataServer.post(`api/getInspector`, {
        warehouse_id: param.apiParam.warehouse_id,
        issue_pin: param.apiParam.issue_pin
      });
      
      return data.data;
    } catch (error) {
      // return error;
    }
  }
);