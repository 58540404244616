import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface EditHealthParamterState {
    EditHealthParamter: {
        load: boolean;
        data: [];
    };
}

const initialState: EditHealthParamterState = {
    EditHealthParamter: {
        load: false,
        data: [],
    },
};

export const EditHealthParamterSlice = createSlice({
    name: "EditHealthParamter",
    initialState,
    reducers: {
        resetEditHealthParamterState: (state: any) => {
            state.EditHealthParamter.data = []; // Reset state to initial values
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(EditHealthParamterData.pending, (state: EditHealthParamterState) => {
                state.EditHealthParamter.load = true;
            })
            .addCase(EditHealthParamterData.fulfilled, (state: EditHealthParamterState, action: any) => {
                state.EditHealthParamter.data = action.payload;
                state.EditHealthParamter.load = false;
            })
            .addCase(EditHealthParamterData.rejected, (state: EditHealthParamterState) => {
                state.EditHealthParamter.load = false;
            });
    },
});

export const { resetEditHealthParamterState } = EditHealthParamterSlice.actions;

export default EditHealthParamterSlice.reducer;

export const EditHealthParamterData = createAsyncThunk(
    "EditHealthParamter/EditHealthParamterData",
    async (
        param: {
            apiParam: {
                probability_of_event: string;
                effect_of_event: string;
                element_name: any;
                element_issue_name: any;
                sub_issue_name: any;
                warehouse_id: any;
                tpi_firm_id: any;
            };
            navigation: any;
        },
        thunkAPI
    ) => {
        try {
            const { data } = await DataServer.post("api/editElementHealthData", {
                probability_of_event: param.apiParam.probability_of_event,
                effect_of_event: param.apiParam.effect_of_event,
                element_name: param.apiParam.element_name,
                element_issue_name: param.apiParam.element_issue_name,
                sub_issue_name: param.apiParam.sub_issue_name,
                warehouse_id: param.apiParam.warehouse_id,
                tpi_firm_id: param.apiParam.tpi_firm_id,
            });
            return data.data;
        } catch (error) {
            // Handle error
        }
    }
);
