import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";

interface IssueListState {
  issuelist: {
    load: boolean;
    data: [];
  };
}

export const IssueImageSlice = createSlice({
  name: "issuelist",
  initialState: {
    issuelist: {
      load: false,
      data: [],
    },
  } as IssueListState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchIssueListForDownload.pending, (state: IssueListState) => {
        state.issuelist.load = true;
      })
      .addCase(
        fetchIssueListForDownload.fulfilled,
        (state: IssueListState, action: any) => {
          state.issuelist.data = action.payload;
          state.issuelist.load = false;
        }
      )
      .addCase(fetchIssueListForDownload.rejected, (state: IssueListState) => {
        state.issuelist.load = false;
      });
  },
});

export default IssueImageSlice.reducer;

export const fetchIssueListForDownload = createAsyncThunk(
  "issuelist/fetchIssueListForDownload",
  async (param: { apiParam: { project_pin: any, layout_id: any } }, thunkAPI) => {
    try {
      const { data } = await DataServer.post("api/getTpiReportedIssueExcelData", {
          project_pin: param.apiParam.project_pin,
          layout_id: param.apiParam.layout_id
        }
      );
      return data.data;
    } catch (error) {
      // console.log("Error", error);
    }
  }
);
