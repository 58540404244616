import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";

interface IssuesState {
  issue: {
    load: boolean;
    data: [];
  };
}

export const IssueSlice = createSlice({
  name: "issue",
  initialState: {
    issue: {
      load: false,
      data: [],
    },
  } as IssuesState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchVerifiedIssues.pending, (state: IssuesState) => {
        state.issue.load = true;
      })
      .addCase(fetchVerifiedIssues.fulfilled, (state: IssuesState, action: any) => {
        state.issue.data = action.payload;
        state.issue.load = false;
      })
      .addCase(fetchVerifiedIssues.rejected, (state: IssuesState) => {
        state.issue.load = false;
      })
      .addCase(fetchVerifiedIssuesDetails.pending, (state: IssuesState) => {
        state.issue.load = true;
      })
      .addCase(fetchVerifiedIssuesDetails.fulfilled, (state: IssuesState, action: any) => {
        state.issue.data = action.payload;
        state.issue.load = false;
      })
      .addCase(fetchVerifiedIssuesDetails.rejected, (state: IssuesState) => {
        state.issue.load = false;
      })
      .addCase(fetchTpiReportedIssuesList.pending, (state: IssuesState) => {
        state.issue.load = true;
      })
      .addCase(fetchTpiReportedIssuesList.fulfilled, (state: IssuesState, action: any) => {
        state.issue.data = action.payload;
        state.issue.load = false;
      })
      .addCase(fetchTpiReportedIssuesList.rejected, (state: IssuesState) => {
        state.issue.load = false;
      });
  },
});

export default IssueSlice.reducer;

export const fetchVerifiedIssues = createAsyncThunk(
  "issue/fetchVerifiedIssues",
  async (param: {apiParam: {per_page: any, page: any, status: any, search: any, warehouse_id: any}}, thunkAPI) => {
    // console.log("warehouse_id", warehouse_id)
    try {
      // thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/verifiedIssueSummary", {
        warehouse_id: param.apiParam.warehouse_id,
        per_page: param.apiParam.per_page,
        page: param.apiParam.page,
        status: param.apiParam.status,
        search: param.apiParam.search
      });
      
      // thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      // thunkAPI.dispatch(toggleLoading());
      // console.log("Error", error);
    }
  }
);

export const fetchVerifiedIssuesDetails = createAsyncThunk(
  "issue/fetchVerifiedIssuesDetails",
  async (param: any, thunkAPI) => {
    // alert("called");
    try {
      // thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.get(`api/viewVerifiedIssues`, {
        verified_issue_detail_pin: param.apiParam.id
      });
      // thunkAPI.dispatch(toggleLoading());
      return data.data[0];
    } catch (error) {
      // thunkAPI.dispatch(toggleLoading());
      // console.log("Error", error);
    }
  }
);
export const fetchTpiReportedIssuesList = createAsyncThunk(
  "reportedissue/fetchTpiReportedIssuesList",
  async (param: {apiParam: {per_page: any, page: any, project_pin: any, search: any, layout_id: any}}, thunkAPI) => {
    try {
      // thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post(`api/getTpiReportedIssueData`, {
        project_pin: param.apiParam.project_pin,
        per_page: param.apiParam.per_page,
        page: param.apiParam.page,
        search: param.apiParam.search,
        layout_id: param.apiParam.layout_id
      });
      // thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      // thunkAPI.dispatch(toggleLoading());
      // console.log("Error", error);
    }
  }
);
