import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";

interface RackDataState {
    racklist: {
    load: boolean;
    data: [];
  };
}

export const RackDataSlice = createSlice({
  name: "racklist",
  initialState: {
    racklist: {
      load: false,
      data: [],
    },
  } as RackDataState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchRackList.pending, (state: RackDataState) => {
        state.racklist.load = true;
      })
      .addCase(fetchRackList.fulfilled, (state: RackDataState, action: any) => {
        state.racklist.data = action.payload;
        state.racklist.load = false;
      })
      .addCase(fetchRackList.rejected, (state: RackDataState) => {
        state.racklist.load = false;
      })
  },
});

export default RackDataSlice.reducer;

export const fetchRackList = createAsyncThunk(
  "racklist/fetchRackList",
  async (param: {apiParam: {warehouse_id: any}}, thunkAPI) => {
    try {
      const { data } = await DataServer.post(`api/getWarehouseRacks`, {
        warehouse_id: param.apiParam.warehouse_id
      });
      
      return data.data;
    } catch (error) {
      // return error;
    }
  }
);