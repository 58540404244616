import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface DashboardRackHealthState {
    rackHealth: {
        load: boolean;
        data: [];
    };
}

export const rackHealthSlice = createSlice({
    name: "rackHealth",
    initialState: {
        rackHealth: {
            load: false,
            data: [],
        },
    } as DashboardRackHealthState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchDashboardrackHealthCounts.pending, (state: DashboardRackHealthState) => {
                state.rackHealth.load = true;
            })
            .addCase(fetchDashboardrackHealthCounts.fulfilled, (state: DashboardRackHealthState, action: any) => {
                state.rackHealth.data = action.payload;
                state.rackHealth.load = false;
            })
            .addCase(fetchDashboardrackHealthCounts.rejected, (state: DashboardRackHealthState) => {
                state.rackHealth.load = false;
            })
            .addCase(fetchrackHealthCounts.pending, (state: DashboardRackHealthState) => {
                state.rackHealth.load = true;
            })
            .addCase(fetchrackHealthCounts.fulfilled, (state: DashboardRackHealthState, action: any) => {
                state.rackHealth.data = action.payload;
                state.rackHealth.load = false;
            })
            .addCase(fetchrackHealthCounts.rejected, (state: DashboardRackHealthState) => {
                state.rackHealth.load = false;
            });
    },
});

export default rackHealthSlice.reducer;

export const fetchDashboardrackHealthCounts = createAsyncThunk(
    "rackHealth/fetchDashboardrackHealthCounts",
    async (param: any) => {
        try {
            const { data } = await DataServer.post("api/getRackHealthData", {
                warehouse_id: param.apiParam.warehouse_id
            });
            return data.data;
        } catch (error) {
            // console.log("Error in Login", error);
        }
    }
);

export const fetchrackHealthCounts = createAsyncThunk(
    "rackHealth/fetchrackHealthCounts",
    async (param: {apiParam: { project_pin: any, layout_id: any }}) => {
        try {
            const { data } = await DataServer.post("api/getTpiRackHealthData", {
                project_pin: param.apiParam.project_pin, 
                layout_id: param.apiParam.layout_id
            });
            return data.data;
        } catch (error) {
            // console.log("Error in Login", error);
        }
    }
);
