import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";
import {
    activateSweetAlert,
    deActivateSweetAlert,
  } from "../SweetAlert";

interface SyncTpiDataState {
  SyncTpiData: {
    load: boolean;
    data: [];
  };
}

export const SyncTpiDataSlice = createSlice({
  name: "SyncTpiData",
  initialState: {
    SyncTpiData: {
      load: false,
      data: [],
    },
  } as SyncTpiDataState,
  reducers: {},
});

export default SyncTpiDataSlice.reducer;

export const SyncTpiData = createAsyncThunk(
  "SyncTpiData/SyncTpiData",
  async (param: {apiParam : {project_pin: any, userId: any}, navigation: any} , thunkAPI) => {
    try {

      thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/syncTpiProjectData", {
        project_pin: param.apiParam.project_pin,
        login_user_id: param.apiParam.userId
      });
      thunkAPI.dispatch(toggleLoading());

      thunkAPI.dispatch(
        activateSweetAlert({
          show: true,
          type: "success",
          title: "Success!",
          confirmBtnText: 'Done',
          message: data.message,
          onConfirm: () => {
            handleonConfirm()
          },
        })
      );

      const handleonConfirm = () => {
        thunkAPI.dispatch(deActivateSweetAlert());
      };
      return data.data;
    } catch (error) {
      thunkAPI.dispatch(toggleLoading());
      return error;
    }
  }
);


