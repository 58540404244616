import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";
import { activateSweetAlert, deActivateSweetAlert } from "../../SweetAlert";

interface ComparedIssuesDetailsState {
  comparedissuedetails: {
    load: boolean;
    data: [];
  };
}

export const ComparedIssueDetails = createSlice({
  name: "comparedissuedetails",
  initialState: {
    comparedissuedetails: {
      load: false,
      data: [],
    },
  } as ComparedIssuesDetailsState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchDuplicateIssueDetails.pending, (state: ComparedIssuesDetailsState) => {
        state.comparedissuedetails.load = true;
      })
      .addCase(fetchDuplicateIssueDetails.fulfilled, (state: ComparedIssuesDetailsState, action: any) => {
        state.comparedissuedetails.data = action.payload;
        state.comparedissuedetails.load = false;
      })
      .addCase(fetchDuplicateIssueDetails.rejected, (state: ComparedIssuesDetailsState) => {
        state.comparedissuedetails.load = false;
      });
  },
});

export default ComparedIssueDetails.reducer;

export const fetchDuplicateIssueDetails = createAsyncThunk(
  "comparedissuedetails/fetchDuplicateIssueDetails",
  async (param: {apiParam: {issue_pin: any, inhouse_issue_pin: any, warehouse_id: any, status: any}}, thunkAPI) => {
    try {
      // thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post(`api/viewDuplicateIssueDetails`, {
        warehouse_id: param.apiParam.warehouse_id,
        issue_pin: param.apiParam.issue_pin,
        inhouse_issue_pin: param.apiParam.inhouse_issue_pin,
        status: param.apiParam.status
      });

      // thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      // thunkAPI.dispatch(toggleLoading());
    }
  }
);

export const postMergeIssue = createAsyncThunk(
    "comparedissuedetails/postMergeIssue",
    async (param: {apiParam: {issue_pin: any, login_user_id: any, warehouse_id: any}, navigation: any}, thunkAPI) => {
      try {
          const { data } = await DataServer.post(`api/mergeIssue`, {
              warehouse_id: param.apiParam.warehouse_id,
              issue_pin: param.apiParam.issue_pin,
              login_user_id: param.apiParam.login_user_id,
            });
            
        thunkAPI.dispatch(toggleLoading());

        thunkAPI.dispatch(
            activateSweetAlert({
                show: true,
                type: "success",
                title: "Success!",
                confirmBtnText: 'Done',
                message: data.message,
                onConfirm: () => {
                handleonConfirm()
                },
            })
        );

        const handleonConfirm = () => {
            param.navigation("/compare-statistics");
            thunkAPI.dispatch(deActivateSweetAlert());
        };
  
        thunkAPI.dispatch(toggleLoading());
        return data.data;
      } catch (error) {
        thunkAPI.dispatch(toggleLoading());
      }
    }
);

export const postkeepSeparateIssues = createAsyncThunk(
    "comparedissuedetails/postkeepSeparateIssues",
    async (param: {apiParam: {issue_pin: any, login_user_id: any, warehouse_id: any, inhouse_issue_pin: any}, navigation: any}, thunkAPI) => {
      try {
          const { data } = await DataServer.post(`api/keepSeparateIssues`, {
              warehouse_id: param.apiParam.warehouse_id,
              issue_pin: param.apiParam.issue_pin,
              login_user_id: param.apiParam.login_user_id,
              inhouse_issue_pin: param.apiParam.inhouse_issue_pin
            });
            
        thunkAPI.dispatch(toggleLoading());

        thunkAPI.dispatch(
            activateSweetAlert({
                show: true,
                type: "success",
                title: "Success!",
                confirmBtnText: 'Done',
                message: data.message,
                onConfirm: () => {
                handleonConfirm()
                },
            })
        );

        const handleonConfirm = () => {
            param.navigation("/compare-statistics");
            thunkAPI.dispatch(deActivateSweetAlert());
        };
  
        thunkAPI.dispatch(toggleLoading());
        return data.data;
      } catch (error) {
        thunkAPI.dispatch(toggleLoading());
      }
    }
);
