import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";

interface WarehouseDetailsState {
  WarehouseDetails: {
    load: boolean;
    data: [];
  };
}

export const WarehouseDetailsSlice = createSlice({
  name: "WarehouseDetails",
  initialState: {
    WarehouseDetails: {
      load: false,
      data: [],
    },
  } as WarehouseDetailsState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchWarehouseDetails.pending, (state: WarehouseDetailsState) => {
        state.WarehouseDetails.load = true;
      })
      .addCase(fetchWarehouseDetails.fulfilled, (state: WarehouseDetailsState, action: any) => {
        state.WarehouseDetails.data = action.payload;
        state.WarehouseDetails.load = false;
      })
      .addCase(fetchWarehouseDetails.rejected, (state: WarehouseDetailsState) => {
        state.WarehouseDetails.load = false;
      });
  },
});

export default WarehouseDetailsSlice.reducer;

export const fetchWarehouseDetails = createAsyncThunk(
  "WarehouseDetails/fetchWarehouseDetails",
  async (param: {apiParam: { project_pin: any, layout_id: any }}, thunkAPI) => {
    try {
      // thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/warehouseDataForInspectionStatistics", {
        project_pin: param.apiParam.project_pin,
        layout_id: param.apiParam.layout_id
      });
      // thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      // thunkAPI.dispatch(toggleLoading());
    }
  }
);
