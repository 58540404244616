import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";

interface IssueListState {
  IssueList: {
    load: boolean;
    data: [];
  };
}

export const IssueListSlice = createSlice({
  name: "IssueList",
  initialState: {
    IssueList: {
      load: false,
      data: [],
    },
  } as IssueListState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchIssueList.pending, (state: IssueListState) => {
        state.IssueList.load = true;
      })
      .addCase(fetchIssueList.fulfilled, (state: IssueListState, action: any) => {
        state.IssueList.data = action.payload;
        state.IssueList.load = false;
      })
      .addCase(fetchIssueList.rejected, (state: IssueListState) => {
        state.IssueList.load = false;
      })
      .addCase(fetchMasterIssueList.pending, (state: IssueListState) => {
        state.IssueList.load = true;
      })
      .addCase(fetchMasterIssueList.fulfilled, (state: IssueListState, action: any) => {
        state.IssueList.data = action.payload;
        state.IssueList.load = false;
      })
      .addCase(fetchMasterIssueList.rejected, (state: IssueListState) => {
        state.IssueList.load = false;
      });
  },
});

export default IssueListSlice.reducer;

export const fetchIssueList = createAsyncThunk(
  "users/fetchIssueList",
  async (param: { apiParam : {element_pin: any, page: any, per_page: any, warehouse_id: any, tpi_firm_id: any, search: any} }, thunkAPI) => {
    try {
      thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/getElementIssueList", {
        warehouse_id: param.apiParam.warehouse_id,
        element_pin: param.apiParam.element_pin,
        page: param.apiParam.page,
        per_page: param.apiParam.per_page,
        tpi_firm_id: param.apiParam.tpi_firm_id,
        search: param.apiParam.search
      });
      thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      thunkAPI.dispatch(toggleLoading());
      // console.log("Error", error);
    }
  }
);

export const fetchMasterIssueList = createAsyncThunk(
  "element/fetchMasterIssueList",
  async (param: { apiParam : {element_pin: any, page: any, per_page: any, search: any} }, thunkAPI) => {
    try {
      thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/getElementIssueMaster", {
        element_pin: param.apiParam.element_pin,
        page: param.apiParam.page,
        per_page: param.apiParam.per_page,
        search: param.apiParam.search
      });
      thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      thunkAPI.dispatch(toggleLoading());
      // console.log("Error", error);
    }
  }
);
