import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { activateAlert } from "../Alert";
import {
    activateSweetAlert,
    deActivateSweetAlert,
} from "../SweetAlert";
import { toggleLoading } from "../LoadingSlice";
 
export interface LicenceData {
    company_id: string;
    warehouse_name: string;
    address_line1: string;
    address_line2: string;
    city: string;
    district_id: number;
    state_id: number;
    country_id: number;
    pin_code: string;
    first_name: string;
    last_name: string;
    email: string;
    contact_number: string;
    package_id: number
    // package_description: string;
    final_amount: number;
    // package_final_amount: number;
    // validity: string;
    payment_method: string;
    minimum_amount: string;
    payment_term: number;
    coupon_code: string;
    payment_due_date: string;
    transaction_id: string;
    payment_mode: string;
    bank_name: string;
    cheque_no: string;
    po_no: string;
    upload_po: string;
    remark: string;
    transaction_date: string;
    advanced_amount: number;
    due_amount: number;
    created_at: string;
    status: string;
    updated_at: string;
    vendor_id:string;
    created_by:any;
    company_type: string;
    warehouse_timezone: string;
    company_name: string;
}

interface LicenceState {
    licence: {
        load: boolean;
        data: Array<LicenceData>;
        errors: Array<LicenceData>;
    };
    licenceData?: LicenceData;
    editLicence: {
        load: boolean;
    };
}
const initialState = {
    licence: {
        load: false,
        data: [],
        errors: []
    },
    licenceData: {
        company_id: "",
        warehouse_name: "",
        address_line1: "",
        address_line2: "",
        city: "",
        district_id: 0,
        state_id: 0,
        country_id: 0,
        pin_code: "",
        first_name: "",
        last_name: "",
        email: "",
        contact_number: "",
        package_id: 0,
        // package_description: "",
        final_amount: 0,
        // package_final_amount: 0,
        // validity: "",
        payment_method: "",
        minimum_amount: "",
        payment_term: 0,
        coupon_code: "",
        payment_due_date: "",
        transaction_id: "",
        payment_mode: "",
        bank_name: "",
        cheque_no: "",
        po_no: "",
        upload_po: "",
        remark: "",
        transaction_date: "",
        advanced_amount: 0,
        due_amount: 0,
        created_at: "",
        status: "",
        updated_at: "",
        vendor_id:"",
        created_by: 0,
        company_type: "",
        warehouse_timezone: "",
        company_name: ""
    },
    editLicence: {
        load: false,
    },
} as LicenceState;

export const LicenceSlice = createSlice({
    name: "licence",
    initialState,
    reducers: {
        resetLicenceData: (state: LicenceState) => {
            state.licenceData = initialState.licenceData;
        },
        updateLicenceData: (state: LicenceState, action: { payload: LicenceData }) => {
            state.licenceData = action.payload;
        },
        failLicenceData: (state, action) => {
            state.licence.errors = action.payload.errors;
        },
    },
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchLicenceList.pending, (state: LicenceState) => {
                state.licence.load = true;
            })
            .addCase(fetchLicenceList.fulfilled, (state: LicenceState, action: any) => {
                state.licence.data = action.payload;
                state.licence.load = false;
            })
            .addCase(fetchLicenceList.rejected, (state: LicenceState) => {
                state.licence.load = false;
            })
            .addCase(fetchLicenceDetails.pending, (state: LicenceState) => {
                state.editLicence.load = true;
            })
            .addCase(fetchLicenceDetails.fulfilled, (state: LicenceState, action: any) => {
                state.licenceData = action.payload;
                state.editLicence.load = false;
            })
            .addCase(fetchLicenceDetails.rejected, (state: LicenceState) => {
                state.editLicence.load = false;
            });
    },
});
export const { updateLicenceData, resetLicenceData, failLicenceData } = LicenceSlice.actions;
export default LicenceSlice.reducer;

export const fetchLicenceList = createAsyncThunk(
    "licence/fetchLicenceList",
    async (param: { apiParam: any }, thunkAPI) => {
        try {
            const { data } = await DataServer.get("api/licenseSummary?page="+param.apiParam.page+"&per_page="+param.apiParam.per_page + "&login_user_id=" + param.apiParam.login_user_id + "&search=" + param.apiParam.search);
            return data.data;
        } catch (error) {
            // console.log("Error in Login", error);
        }
    }
);

export const addLicence = createAsyncThunk(
    "licence/addLicence",
    async (param: { apiParam: any, navigation: any }, thunkAPI) => {
        try {
            thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post(
                "api/generateWarehouseLicense",
                param.apiParam
            );

            thunkAPI.dispatch(toggleLoading());

            thunkAPI.dispatch(
                activateSweetAlert({
                    show: true,
                    type: "success",
                    title: "Success!",
                    confirmBtnText: 'Done',
                    message: data.message,
                    onConfirm: () => {
                        handleonConfirm()
                    },
                })
            );

            const handleonConfirm = () => {
                param.navigation("/licence-summary");
                thunkAPI.dispatch(deActivateSweetAlert());
            };
            return data.data;
        } catch (error) {
            thunkAPI.dispatch(failLicenceData(error));
            thunkAPI.dispatch(toggleLoading());
            return error;
        }
    }
);

export const fetchLicenceDetails = createAsyncThunk(
    "LicenceDetails/fetchLicenceDetails",
    async (param: { apiParam : {license_pin : any, login_user_id: any} }, thunkAPI) => {
      try {      
        const { data } = await DataServer.post("api/viewLicenseDetails", {
          license_pin : param.apiParam.license_pin,
          login_user_id : param.apiParam.login_user_id
        });
  
        return data.data;
  
      } catch (error) {
        return(error);
      }
    }
  );

  export const renewLicence = createAsyncThunk(
    "licence/renewLicence",
    async (param: { apiParam: any, navigation: any }, thunkAPI) => {
        try {
            thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post("api/renewLicense", {
                license_pin: param?.apiParam?.license_pin,
                login_user_id: param?.apiParam?.login_user_id,
                package_id: param?.apiParam?.package_id,
                license_status: param?.apiParam?.license_status,
            });

            thunkAPI.dispatch(toggleLoading());

            thunkAPI.dispatch(
                activateSweetAlert({
                    show: true,
                    type: "success",
                    title: "Success!",
                    confirmBtnText: 'Done',
                    message: data.message,
                    onConfirm: () => {
                        handleonConfirm()
                    },
                })
            );

            const handleonConfirm = () => {
                param.navigation("/licence-summary");
                thunkAPI.dispatch(deActivateSweetAlert());
            };
            return data.data;
        } catch (error) {
            thunkAPI.dispatch(failLicenceData(error));
            thunkAPI.dispatch(toggleLoading());
            return error;
            // thunkAPI.dispatch(
            //     activateSweetAlert({
            //         show: true,
            //         type: "danger",
            //         title: "Error!",
            //         confirmBtnText: 'Done',
            //         message: error,
            //         onConfirm: () => {},
            //     })
            // );
        }
    }
);

// export const editLicence = createAsyncThunk(
//     "licence/editLicence",
//     async (param: { apiParam: any }, thunkAPI) => {
//         try {
//             const { data } = await DataServer.post("api/updateCompany", param.apiParam);

//             thunkAPI.dispatch(
//                 activateAlert({
//                     isAlertActive: true,
//                     title: "Alert",
//                     content: data.message,
//                 })
//             );
//             return data.data;
//         } catch (error) {
//             console.log("Error in Login", error);
//         }
//     }
// );
