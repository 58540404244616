export enum ROLES {
  "SUPERADMIN" = 1,
  "SUBADMIN" = 2,
  "RESELLER" = 3,
  "WAREHOUSEOWNER" = 4,
  "WAREHOUSEADMIN" = 5,
  "PRSES" = 6,
  "INSPECTOR" = 7,
  "SETUPADMIN" = 8,
  "TPIMANAGER" = 9,
  "TPILEVELAINSPECTOR" = 10,
  "TPIOWNER" = 11,
  "PALLETMANAGER" = 12,
  "MANAGEMENTUSER" = 13,
  "TPILEVELBINSPECTOR" = 14,
}
export const RolesLabel = new Map<number, string>([
  [ROLES.SUPERADMIN, "Super Admin"],
  [ROLES.SUBADMIN, "Sub Admin"],
  [ROLES.RESELLER, "Reseller"],
  [ROLES.WAREHOUSEADMIN, "Ware House Admin"],
  [ROLES.WAREHOUSEOWNER, "Ware house Owner"],
  [ROLES.PRSES, "PRSES"],
  [ROLES.INSPECTOR, "Inspector"],
  [ROLES.SETUPADMIN, "Setup Admin"],
  [ROLES.TPIMANAGER, "TPI Manager"],
  [ROLES.TPILEVELAINSPECTOR, "TPI Level A Inspector"],
  [ROLES.TPIOWNER, "Tpi Owner"],
  [ROLES.PALLETMANAGER, "Pallet Manager"],
  [ROLES.MANAGEMENTUSER, "Management User"],
  [ROLES.TPILEVELBINSPECTOR, "TPI Level B Inspector"],
]);
export const dropdownRoles: {
  [key: string]: any;
} = {
  SUPERADMIN: [
    {
      key: ROLES.SUBADMIN,
      value: RolesLabel.get(ROLES.SUBADMIN),
    },
    {
      key: ROLES.MANAGEMENTUSER,
      value: RolesLabel.get(ROLES.MANAGEMENTUSER),
    },
  ],
  PRSES: [
    {
      key: ROLES.INSPECTOR,
      value: RolesLabel.get(ROLES.INSPECTOR),
    },
    {
      key: ROLES.PALLETMANAGER,
      value: RolesLabel.get(ROLES.PALLETMANAGER),
    },
  ],
  WAREHOUSEADMIN: [
    {
      key: ROLES.PRSES,
      value: RolesLabel.get(ROLES.PRSES),
    },
  ],
  TPIMANAGER: [
    {
      key: ROLES.TPILEVELAINSPECTOR,
      value: RolesLabel.get(ROLES.TPILEVELAINSPECTOR),
    },
    {
      key: ROLES.SETUPADMIN,
      value: RolesLabel.get(ROLES.SETUPADMIN),
    },
  ],
  TPIOWNER: [
    {
      key: ROLES.TPIMANAGER,
      value: RolesLabel.get(ROLES.TPIMANAGER),
    },
    {
      key: ROLES.TPILEVELAINSPECTOR,
      value: RolesLabel.get(ROLES.TPILEVELAINSPECTOR),
    },
    {
      key: ROLES.TPILEVELBINSPECTOR,
      value: RolesLabel.get(ROLES.TPILEVELBINSPECTOR),
    },
    {
      key: ROLES.SETUPADMIN,
      value: RolesLabel.get(ROLES.SETUPADMIN),
    },
  ],
};
