import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";

interface ElementSummaryState {
  elementSummary: {
    load: boolean;
    data: [];
  };
}

export const elementSummarySlice = createSlice({
  name: "elementSummary",
  initialState: {
    elementSummary: {
      load: false,
      data: [],
    },
  } as ElementSummaryState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchelementSummary.pending, (state: ElementSummaryState) => {
        state.elementSummary.load = true;
      })
      .addCase(fetchelementSummary.fulfilled, (state: ElementSummaryState, action: any) => {
        state.elementSummary.data = action.payload;
        state.elementSummary.load = false;
      })
      .addCase(fetchelementSummary.rejected, (state: ElementSummaryState) => {
        state.elementSummary.load = false;
      })
      .addCase(fetchmasterelementSummary.pending, (state: ElementSummaryState) => {
        state.elementSummary.load = true;
      })
      .addCase(fetchmasterelementSummary.fulfilled, (state: ElementSummaryState, action: any) => {
        state.elementSummary.data = action.payload;
        state.elementSummary.load = false;
      })
      .addCase(fetchmasterelementSummary.rejected, (state: ElementSummaryState) => {
        state.elementSummary.load = false;
      });
  },
});

export default elementSummarySlice.reducer;

export const fetchelementSummary = createAsyncThunk(
  "users/fetchelementSummary",
  async (param: { apiParam: {page: any, per_page: any, search: any, warehouse_id: any, tpi_firm_id: any} }, thunkAPI) => {
    try {
      // thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/getElementList", {
        warehouse_id: param.apiParam.warehouse_id,
        page: param.apiParam.page,
        per_page: param.apiParam.per_page,
        search: param.apiParam.search, 
        tpi_firm_id: param.apiParam.tpi_firm_id
      });
      // thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      // thunkAPI.dispatch(toggleLoading());
      // console.log("Error", error);
    }
  }
);

export const fetchmasterelementSummary = createAsyncThunk(
  "element/fetchmasterelementSummary",
  async (param: { apiParam: {page: any, per_page: any, search: any} }, thunkAPI) => {
    try {
      // thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/getElementsMaster", {
        page: param.apiParam.page,
        per_page: param.apiParam.per_page,
        search: param.apiParam.search
      });
      // thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      // thunkAPI.dispatch(toggleLoading());
      // console.log("Error", error);
    }
  }
);
